<template>
  <v-menu v-if="currentUser" :nudge-width="100">
    <template v-slot:activator="{ on }">
      <v-btn icon color="transparent" class="me-btn" v-on="on">
        <!-- <v-avatar size="25">
              <img src="https://randomuser.me/api/portraits/men/85.jpg" />
        </v-avatar>-->
        <avatar
          :username="currentUser.firstName + ' ' + currentUser.lastName"
          :size="25"
          color="white"
          class="mr-1"
          style="user-select: none;"
        ></avatar>
      </v-btn>
    </template>

    <v-list>
      <v-list-item :to="{ name: 'MyAccount' }">
        <v-list-item-title>My Profile</v-list-item-title>
      </v-list-item>
      <v-list-item @click="logout">
        <v-list-item-title>Logout</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import Avatar from 'vue-avatar'
import { apolloClient } from '@/vue-apollo'
import LOGOFF from '@/graphql/Logoff.gql'

export default {
  name: 'UserMenu',
  components: {
    Avatar
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser
    }
  },
  methods: {
    logout() {
      var vm = this
      // Call server logout method

      this.$apollo
        .mutate({
          mutation: LOGOFF,
          variables: {
            userID: vm.currentUser.id
          }
        })
        .then(() => {
          // vm.$cookies.remove('hpa')
          vm.$store.commit('SET_USER', null)
          apolloClient.cache.reset()
          // this.$apollo.reset()
          vm.$router.push('/login')
        })
        .catch(() => {
          vm.$store.commit('showSnackbar', {
            active: true,
            message: 'Something went wrong'
          })
        })
    }
  }
}
</script>

<style></style>
