<template>
  <v-snackbar
    v-model="snackbar.active"
    :timeout="snackbar.timeout"
    :absolute="snackbar.absolute"
    :top="snackbar.top"
    :right="snackbar.right"
    :bottom="snackbar.bottom"
    :left="snackbar.left"
  >
    {{ snackbar.message }}
    <template v-slot:action>
      <v-btn color="blue" text @click="hideSnackbar">{{
        snackbar.dismissText
      }}</v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: 'GlobalSnackbar',
  computed: {
    snackbar() {
      return this.$store.state.snackbar
    }
  },
  methods: {
    hideSnackbar() {
      this.$store.commit('hideSnackbar')
    }
  }
}
</script>

<style></style>
