<template>
  <v-menu bottom left>
    <template v-slot:activator="{ on }">
      <v-btn icon v-on="on">
        <v-icon>mdi-apps</v-icon>
      </v-btn>
    </template>

    <v-list v-if="userApps">
      <v-list-item
        v-for="app in orderBy(userApps, 'name')"
        :key="app.id"
        :to="app.url"
        :item-value="setCurrentApp(app)"
      >
        <v-list-item-icon>
          <v-icon>{{ app.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content
          ><span class="font-weight-bold grey--text text--darken-2">{{
            app.name
          }}</span></v-list-item-content
        >
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import Vue2Filters from 'vue2-filters'

export default {
  name: 'AppSwitcher',
  mixins: [Vue2Filters.mixin],
  data: () => ({}),
  // apollo: {
  //   userApps: {
  //     query: APP_LIST
  //   }
  // },
  computed: {
    userApps() {
      return this._.filter(this.$store.state.userApps, function(item) {
        return item.active === true
      })
    }
  },
  created() {},
  methods: {
    setCurrentApp(app) {
      if (this.$route.meta.appName.toLowerCase() === app.name.toLowerCase()) {
        this.$emit('set-current-app', app)
      }
    },
    appActive(appID) {
      var idx = this._.findIndex(this.userApps, function(item) {
        return item.appID === appID && item.active
      })

      if (idx > -1) {
        return true
      }

      return false
    }
  }
}
</script>

<style></style>
